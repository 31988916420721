.blackBox {
    color: white;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    &.-dark  {
        background: rgba(0, 0, 0, 0.4);
    }
}

.educateBox {
    color: #D4D4D4;
    svg {
        fill: #D4D4D4;
    }
}