.icon {
    fill: #96B7CA;
}

.hover {
    fill: #CAE4F2;
}

.active {
    fill: #ffffff;
}

.yellow {
    fill: #FEC30C;
}
