@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-BoldItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-ExtraBold.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/OpenSans/OpenSans-ExtraBoldItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

/* M PLUS */
@font-face {
    font-family: "MPlus";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/MPLUS1-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

/* Chunk Five */
@font-face {
    font-family: "Chunk Five";
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/Chunkfive.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

/* Ubuntu Mono */
@font-face {
    font-family: 'Ubuntu Mono';
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/UbuntuMono-Regular.woff2") format('woff2');
}

/* Secured */
@font-face {
    font-family: "Secured";
    font-style: normal;
    font-weight: 400;
    src: url("https://tsnvcs-fonts.s3.us-east-2.amazonaws.com/password.woff");
}