@use "./variables";
@use "sass:math";

@mixin dropdown($color: var(--greyish-brown)) {
    content: "";
    display: inline-block;

    position: absolute;
    right: 0;
    top: 27%;

    border: solid $color;
    $arrow-width: 0.0625rem;
    border-width: 0 $arrow-width $arrow-width 0;
    padding: 0.15625rem;

    transition: 0.3s all;
    transform: rotate(45deg);
}

@mixin attentionInputText($color: var(--cinnabar)) {
    font-size: 0.8125rem;
    color: $color;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s linear;
    word-wrap: break-word;
    text-align: left;
    margin: 0;
    padding: 0;
}

@mixin resetButton {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    cursor: pointer;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &:focus {
        outline: none;
    }
}

@mixin svgColors($color) {
    [data-fill] {
        fill: $color;
    }
    [data-stroke] {
        stroke: $color;
    }
}

@function stripUnit($number) {
    @if type-of($number) == "number" and not unitless($number) {
        @return math.div($number, ($number * 0 + 1));
    }

    @return $number;
}

// $startSize, $minSize, $breakPointInRemNumber only in rem
@mixin remAdaptiveValue(
    $property,
    $startSize,
    $minSize,
    $breakPoint: variables.$mobileBreakPoint,
    $minBreakPoint: variables.$minMobileScreenWidth
) {
    $startSizeValue: stripUnit($startSize);
    $minSizeValue: stripUnit($minSize);
    $breakPointValue: stripUnit($breakPoint);
    $minBreakPointValue: stripUnit($minBreakPoint);

    $addSize: $startSizeValue - $minSizeValue;

    $adaptiveWidth: $breakPointValue - $minBreakPointValue;

    $breakPointRem: $breakPointValue + rem;

    @media (max-width: $breakPointRem) {
        #{$property}: calc(
            #{$minSizeValue + rem} + #{$addSize} *
            ((100vw - #{variables.$minMobileScreenWidth}) / #{$adaptiveWidth})
        );
    }
}

@mixin buttonLoader($color1, $color2, $loaderWidth: 64px) {
    display: block;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    overflow: hidden;
    border-radius: 0.188rem;

    &::before {
        display: block;

        width: calc(100% + #{$loaderWidth});
        height: 100%;

        content: "";
        animation: inProgress 1s linear infinite;

        background-repeat: repeat;
        background-size: $loaderWidth;

        background-image: linear-gradient(
                to right,
                $color1,
                $color2 40%,
                $color1 80%
        );
    }

    @keyframes inProgress {
        from {
            transform: translateX(calc(0px - #{$loaderWidth}));
        }

        to {
            transform: translateX(0);
        }
    }
}
