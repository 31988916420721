@use "../../stylesheets/mixins";

/*
    This file contains default styles for icons.
    Please keep in mind that every icon can be used in different pages and portals,
    so consider writing specialized styles (like colors, animations and hover-effects) in according places.
*/


.svg {
    width: 100%; // Stretch to fit a container
    transition: fill 0.3s ease-out, stroke 0.3s ease-out;
}


.search {
    width: 1.125rem;
    height: 1.125rem;
    fill: #43A6D9;
}

.notify {
    width: 1.125rem;
    height: 1.125rem;
    fill: #ABC5D3;
}

.logout, .certificate {
    fill: #999999;
}

.notification {
    width: 1.375rem;
    height: 1.5rem;
}

.details {
    width: 1.125rem;
    height: 1.125rem;
}

.loading-area {
    width: 3.6875rem;
    height: 4.0625rem;
}

.repository_theme,
.repository_sample,
.repository_brand,
.repository_content {
    width: 1.625rem;
    height: 1rem;
    fill: #97a3ab;
}

.edit,
.history {
    width: 1.125rem;
    height: 1.125rem;
    fill: #43a6d9;
}

.transparentCopy {
    width: 0.9375rem;
    height: 0.9375rem;
    fill: #43a6d9;
}

.entityIndicator {
    width: 0.5rem;
    height: 0.5rem;
    stroke: #cbaf5c;
}


.disabledGroup {
    width: 0.813rem;
    height: 0.813rem;
}

.activeBuild,
.inactiveBuild {
    width: 1rem;
    height: 1rem;
}

.documentation {
    width: 1rem;
    height: 1rem;
    fill: #999999;
}

.questionMark {
    fill: white;
    width: 0.5rem;
    height: 0.875rem;
}

.doubleArrow {
    width: 0.6875rem;
    height: 0.625rem;
    stroke: #999999;
}

.invites {
    width: 3.625rem;
    height: 2.4375rem;
    fill: #dce3e8;
}

.arrow {
    width: 0.438rem;
    height: 0.75rem;
    stroke: #0076b3;
}

.viewsCount {
    width: 1.125rem;
    height: 1.125rem;
}

.activate {
    width: 1.125rem;
    height: 1.125rem;
    fill: #43a6d9;
}

.share {
    width: 1.125rem;
    height: 1.125rem;
}

.noProducts {
    width: 1.375rem;
    height: 0.875rem;
}

.calendar {
    width: 1rem;
    height: 0.875rem;
}

.sort,
.sortAsc,
.sortDesc {
    width: 0.5rem;
    height: 0.563rem;
    fill: #999999;
}

.select-droplist {
    width: 0.7rem;
    height: 0.5rem;
}

.hor-more-photos {
    width: 5.813rem;
    height: 3.2rem;
}

.vert-more-photos {
    width: 3.125rem;
    height: 5.625rem;
}

.offlineScreen {
    width: 1.25rem;
    height: 0.875rem;
}

.screens {
    width: 1.125rem;
    height: 0.875rem;
    fill: #999;
}

.screensNotAssign {
    width: 1.125rem;
    height: 0.8125rem;
    fill: white;
}

.offShedule {
    width: 1rem;
    height: 0.875rem;
    fill: white;
}

.devices {
    width: 1.125rem;
    height: 0.75rem;
    fill: var(--warm-grey);
    stroke: #aaaaaa;
}

.district {
    width: 1.125rem;
    height: 1.125rem;
    fill: var(--warm-grey);
}

.school {
    width: 1.125rem;
    height: 0.75rem;
    fill: var(--warm-grey);
}

.mainGroup {
    width: 0.6875rem;
    height: 0.6875rem;
    fill: none;
}

.list {
    width: 1.125rem;
    height: 1.125rem;
    fill: #999;
}

.user {
    fill: #999999;
    width: 1.125rem;
    height: 1.125rem;
}

.statistics {
    width: 1rem;
    height: 0.938rem;
    stroke: #43a6d9;
}

.userHeader {
    width: 0.9375rem;
    height: 1rem;
    fill: var(--warm-grey);
}

.question {
    width: 1rem;
    height: 1rem;
    fill: #999999;
}

.dragndrop {
    width: 0.25rem;
    height: 0.75rem;
    stroke: #96b7ca;
}

.alert {
    width: 0.875rem;
    height: 1rem;
}

.schools {
    width: 1.125rem;
    height: 0.75rem;
    fill: #aaa;
}

.reset {
    width: 1.125rem;
    height: 1.125rem;
    fill: var(--blue);
}

.droplist2 {
    width: 0.625rem;
    height: 0.375rem;
    stroke: black;
}

.droplist {
    width: 0.625rem;
    height: 0.375rem;
    stroke: var(--greyish-brown);
    fill: none;
}

.SCHOLASTIC,
.CONTENT,
.LMS,
.USERS,
.GROUPS {
    fill: var(--warm-grey);
}

.SCREENS,
.REPOSITORIES,
.THEMES {
    fill: var(--gray-icon-colour);
}

.intervalChanger {
    width: 0.313rem;
    height: 0.75rem;
    fill: #4478ff;
}

.REPOSITORIES,
.COMMON,
.THEMES {
    width: 1.625rem;
    height: 1rem;
}

.SCHOLASTIC {
    width: 1.75rem;
    height: 1rem;
}

.CLASSROOM {
    width: 1.625rem;
    height: 1rem;
    fill: #97A3AB;
}

.BUSINESS {
    width: 1.625rem;
    height: 1rem;
    fill: #97a3ab;
}

.CONTENT,
.CONTENTS {
    width: 1.5rem;
    height: 1rem;
}

.ADMIN {
    width: 1rem;
    height: 1rem;
}

.USERS {
    width: 0.875rem;
    height: 1rem;
}

.GROUPS {
    width: 1.125rem;
    height: 1.125rem;
}

.SCREENS,
.LMS {
    width: 1.3125rem;
    height: 1rem;
}


.REPOSITORY {
    width: 3rem;
    height: 1.875rem;
    fill: white;
}

.SETTINGS {
    fill: white;
    width: 2.625rem;
    height: 1.625rem;
}

.update {
    width: 1.25rem;
    height: 1rem;
    fill: #43a6d9;
}

.ADVERTISEMENT {
    width: 1.25rem;
    height: 1.125rem;
}

.STUDENTS,
.STUDENT {
    width: 2.5rem;
    height: 2.1875rem;
    fill: var(--warm-grey);
}

.WEB_RTC {
    width: 1rem;
    height: 1rem;
    fill: var(--warm-grey);
}

.STUDENT {
    fill: var(--gray-icon-colour);
}

.MENU {
    width: 2.9375rem;
    height: 1.9375rem;
    fill: var(--warm-grey);
}

.districtAccess {
    fill: none;
    width: 0.5rem;
    height: 0.5rem;
}

.file {
    fill: none;
    width: 1.875rem;
    height: 2.5rem;
}

.twitter {
    width: 0.875rem;
    height: 0.8125rem;
    fill: white;
}

.textFeed {
    width: 0.875rem;
    height: 0.75rem;
    fill: none;
    stroke: white;
}

.rss {
    width: 0.9375rem;
    height: 0.9375rem;
}

.portrait {
    width: 0.75rem;
    height: 1rem;
}

.videoType,
.imageType,
.imagePreview {
    width: 1rem;
    height: 1rem;
}

.copyEntity {
    width: 1.125rem;
    height: 1.125rem;
    fill: #43A6D9;
}

.videoPlay {
    width: 0.75rem;
    height: 0.875rem;
    fill: #fec30c;
}

.notConvertedVideo {
    width: 0.875rem;
    height: 1.0625rem;
}

.add,
.view,
.preview,
.revoke {
    max-width: 1.5rem;
    stroke: var(--blue);
    fill: none;
    height: 1.125rem;
}

.delete {
    width: 1rem;
    fill: var(--blue);
    height: 1rem;
}

.hiddenEye {
    width: 1.1rem;
    height: 0.6rem;
}

.add,
.preview {
    width: 0.875rem;
}

.view {
    fill: none !important;
    width: 1.125rem;
    height: 1rem;
}

.revoke {
    fill: #43a6d9;
    width: 1.4rem;
}

.selectAll {
    width: 1.125rem;
    height: 1.125rem;
    fill: #44a6d9;
}

.detach {
    width: auto;
    height: 1.125rem;
}

.add {
    fill: var(--blue);
}

.clock {
    width: 1.0625rem;
    height: 0.875rem;
    fill: var(--warm-grey);
}

.offline {
    width: 1.4375rem;
    height: 0.8125rem;
    fill: var(--white);
}

.schedule {
    width: 0.875rem;
    height: 0.875rem;
    fill: none !important;
    stroke: #43a6d9;
}

.queue {
    width: 0.75rem;
    height: 0.8125rem;
    fill: none !important;
    stroke: #43a6d9;
}

.dnd {
    fill: #808080;
    width: 0.8125rem;
    height: 0.8125rem;
}

.tagFolder,
.contentRepository,
.themeRepository {
    width: 1.375rem;
    height: 1rem;
    position: relative;
    bottom: -0.188rem;
}


.tag {
    width: 1.1875rem;
    height: 0.75rem;
    fill: none;
}

.threeCircles {
    width: 0.75rem;
    height: 0.625rem;
    stroke: #999;
    fill: none;
}

.painting {
    width: 1.0625rem;
    height: 0.75rem;
    fill: none;
}

.viewCount {
    fill: none;
    width: 1.125rem;
    height: 0.8125rem;
}

.image {
    width: 1.0625rem;
    height: 0.75rem;
    fill: var(--warm-grey);
}

.copy {
    width: 1.0625rem;
    height: 0.75rem;
    fill: white;
    stroke: var(--cornflower-blue);
}

.upload {
    width: 1.125rem;
    height: 1.125rem;
    fill: var(--blue);
}

.contentCopy {
    width: 0.5625rem;
    height: 0.5rem;
    fill: var(--dove-grey);
}

.folder {
    width: 1.0625rem;
    height: 0.75rem;
    fill: var(--white) !important;
    stroke: #666;
}

.star {
    width: 0.6875rem;
    height: 0.6875rem;
}

.projectLogo {
    width: 3.5625rem;
    height: 3.125rem;
    fill: #efddb3;
}

.message {
    width: 1rem;
    height: 0.875rem;
    stroke: var(--warm-grey);
    fill: none;
}

.contentCount,
.pendingCount {
    width: 1.0625rem;
    height: 0.75rem;
    fill: #999;
}

.twitch {
    width: 0.8125rem;
    height: 1rem;
    fill: var(--white);
}

.facebook {
    width: 0.875rem;
    height: 0.875rem;
    fill: var(--white);
}

.youtube {
    width: 1rem;
    height: 0.6875rem;
}

.onOffIndicator {
    width: 0.8125rem;
    height: 0.875rem;
    fill: var(--white);
}

.menuTemplate {
    width: 1rem;
    height: 1rem;
    fill: var(--white);
}

.crossLines {
    width: 0.8125rem;
    height: 0.8125rem;
    stroke: var(--salmon);
}

.plus {
    width: 0.875rem;
    height: 0.875rem;
    fill: var(--warm-grey);
}

.calendarCopy,
.calendarPaste,
.calendarDelete {
    width: 0.85rem;
    height: 0.85rem;
    fill: white;
}

.copySign {
    width: 1rem;
    height: 1rem;
    fill: none;
    stroke: #43a6d9;
}

.clockPeriod {
    width: 0.875rem;
    height: 0.875rem;
}

.export {
    width: 0.875rem;
    height: 0.875rem;
    fill: var(--blue);
}

.MESSAGES {
    width: 3.125rem;
    height: 2rem;
    @include mixins.svgColors(#B5CCDA);

    & [data-lines] {
        @include mixins.svgColors(white);
    }
}

.alertContent {
    width: 0.625rem;
    height: 1rem;
    fill: var(--white);
}

.CREATIVE {
    width: 0.9375rem;
    height: 0.875rem;
    fill: #b746c6;
    stroke: none !important;
}

.unlimited {
    width: 1rem;
    height: 0.875rem;
}

.screenWithPlus {
    width: 1.125rem;
    height: 0.813rem;
    fill: var(--blue);
}

.setting {
    width: 1.125rem;
    height: 0.9375rem;
    fill: var(--blue);
}

.map {
    width: 0.8125rem;
    height: 0.8125rem;
    stroke: #44a6d9;
    fill: none !important;
}

.filterSetting {
    width: 1rem;
    height: 0.75rem;
    fill: #afbdc3;
}

.exit {
    stroke: #cccccc;
    fill: none !important;
    width: 0.9375rem;
    height: 0.9375rem;
}

.NEWS {
    width: 2.875rem;
    height: 2rem;
    fill: #97a3ab;
}

.news {
    width: 1.4375rem;
    height: 1.4375rem;
    fill: var(--white);
}

.ATHLETIC {
    fill: var(--warm-grey);
    width: 1.625rem;
    height: 1rem;
}

.COMMUNITY {
    width: 1.4375rem;
    height: 1rem;
    fill: var(--warm-grey);
}

.group {
    width: 1.125rem;
    fill: #444444;
}

.rename {
    width: 1.125rem;
    height: 1.125rem;
    fill: var(--blue);
}

.closedEye,
.openEye {
    width: 1.125rem;
    height: 0.75rem;
    fill: none;
    stroke: #444444;
}

.alertus {
    width: 1.125rem;
    height: 1rem;
    fill: white;
}

.searchDetailed {
    width: 1.1875rem;
    height: 1.0625rem;
    fill: #9b9fa1;
}

.clockArrow {
    width: 1.125rem;
    height: 1.125rem;
    fill: var(--blue);
}

.DEFAULT {
    width: 1.625rem;
    height: 1rem;
    fill: var(--gray-icon-colour);
}

.filterSearchSettings {
    width: 1rem;
    height: 0.75rem;
    fill: #afbdc3;
    stroke: none;
}

.fileFeed {
    width: 0.75rem;
    height: 1rem;
    fill: white;
}

.fileFeedCustom {
    width: 0.75rem;
    height: 1rem;
    fill: white;
}

.warning {
    width: 1.25rem;
    height: 1.125rem;
    fill: #ff7447;
}

.yellow-warning {
    width: 1.25rem;
    height: 1.125rem;
    fill: #FFC719;
}

.user-warning {
    width: 1.25rem;
    height: 1rem;
    fill: #fe8a65;
}

.thin-close {
    width: 1rem;
    height: 1rem;
    fill: #bdced9;

    &:hover {
        fill: #88a4b7;
    }
}

.sample {
    width: 1.125rem;
    height: 1.125rem;

    * > * {
        fill: var(--warm-grey);
    }
}

.contentUsage {
    fill: #666;
    width: 0.5625rem;
    height: 0.5rem;
}

.screenPlay {
    fill: none;
    width: 1.125rem;
    height: 1.125rem;
}

.locked {
    width: 0.625rem;
    height: 0.6875rem;
    fill: none;
}

.unlocked {
    width: 0.625rem;
    height: 0.625rem;
    fill: none;
}

.info {
    width: 1.125rem;
    height: 1.125rem;
    fill: #999999;
}

.chat {
    width: 1.5rem;
    height: 1rem;
    fill: #777;
}

.bulk {
    width: 1.125rem;
    height: 1.125rem;
    stroke: #44a6d9;
    fill: none;
}

.screen_not_lms {
    fill: none;
}

.folder_plus_sign {
    width: 1.1875rem;
    height: 0.75rem;
    fill: #44a6d9;
}

.content_plus_sign {
    width: 1.25rem;
    height: 1.125rem;
    fill: #44a6d9;
}

.mail_with_lines {
    fill: none;
}

.TRIAL {
    width: 1.625rem;
    height: 1.25rem;
}

.help_button {
    width: 1rem;
    height: 0.875rem;
    stroke: #0076b3;
}

.hashtag {
    width: 2rem;
    height: 2rem;
    fill: none;
    stroke: #dce3e8;
}

.key {
    width: 3.25rem;
    height: 3.25rem;
    fill: none;
    stroke: #dce3e8;
}

.test_device {
    fill: none;
    width: 1.125rem;
    height: 1.125rem;
}

.REPORTS {
    width: 3.125rem;
    height: 2rem;
    fill: var(--white);
    stroke: var(--white);
}

.file-log {
    width: 1.125rem;
    height: 1.125rem;
    fill: #43a6d9;
}

.business {
    width: 1.125rem;
    height: 1.125rem;
    fill: none;
    stroke: #999;
}

.add_network {
    width: 0.9375rem;
    height: 1.1875rem;
    fill: #43a6d9;
}

.institution {
    width: 1.125rem;
    height: 1.125rem;
    fill: #999;
}

.content_sign {
    width: 1.125rem;
    height: 0.75rem;
    fill: #43A6D9;
}

.search_with_border {
    width: 1rem;
    height: 1rem;
    fill: #43A6D9;
}

.bonusly {
    height: 1rem;
    width: 1.125rem;
    fill: white;
}