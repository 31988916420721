.contentWrapper {
    padding: 0;

    &.overflowY {
        overflow-y: auto;
    }

    &.-no-min-width {
        min-width: unset;
    }
}
