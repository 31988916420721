@use "../../adaptive/constants.scss";

.portalsLine {
    display: flex;
    max-width: 40rem;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 1.25rem auto 0;
}

@media only screen and (max-width: constants.$homePageBreakPoint) {
    .portalsLine {
        justify-content: space-between;
    }
}

.portalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
    text-decoration: none;

    &:hover {
        .portal {
            background: rgba(255, 255, 255, 0.2);
        }
        .portalName {
            color: var(--white);
        }

        .portalIcon > div {
            background-color: white;
        }
    }
}

.portal {
    width: 5.625rem;
    height: 5.625rem;

    background: rgba(255, 255, 255, 0.07);
    border-radius: 12px;
    transition: all ease-in 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;

    &_hide {
        visibility: hidden;
    }
}

.portalName {
    color: #a0abb6;
    font-size: 0.8125rem;
    margin-top: 0.5rem;
    transition: color ease-in 0.25s;
    text-transform: none;
}

.tooltip {
    cursor: default;
    position: fixed;
    z-index: 2;
    display: flex;

    > div {
        padding: 0.75rem 1.875rem;
        flex-shrink: 0;
        max-width: 25rem;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 6px;
        color: #dfdfdf;
    }
}

.portalIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        background-color: #e2e4e6;
    }
}
