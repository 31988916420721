.copiedHint {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0,0,0,0.9);
    padding: 20px;
    border-radius: 6px;
    color: var(--white);
}

.copySign {
    display: flex;
    align-items: center;
}

.copySign:hover svg {
    stroke: #B06A00;
}

.hiddenInput {
    position: fixed;
    left: -1000px;
    opacity: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.openHint {
    opacity: 1;
}

.closeHint {
    opacity: 0;
}

.copyIcon {
    transform: translateY(0.1875rem);
}

.copied {
    min-width: unset !important;
}