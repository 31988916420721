@use "../../../stylesheets/mixins";
@use "./../../../stylesheets/variables";

$mobileNotificationWidth: 21.25rem;

.notification-component {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 1.375rem;
    padding-bottom: 0.375rem;

    background-color: #4c545b;
    border-radius: 0.375rem;

    transition-duration: 200ms;
    transition-property: opacity, transform;
    transition-timing-function: ease-out;

    width: 100%;
    max-width: $mobileNotificationWidth;
    margin-top: 1.25rem;

    @media (min-width: 25rem) {
        width: 25rem;
    }

    @media screen and (max-width: variables.$mobileBreakPoint) {
        max-width: min(
            calc(100vw - (2 * #{variables.$mobileContainerHorPadding})),
            $mobileNotificationWidth
        );
    }

    &.-hidden {
        opacity: 0;
    }

    $sidePadding: 1.875rem;

    .header,
    .body,
    .detailsActions {
        padding-left: $sidePadding;
        padding-right: $sidePadding;
    }

    .header {
        align-items: center;
        display: flex;
        margin-bottom: 0.75rem;
    }

    .title {
        color: #c0c0c0;
        font-size: 0.8125rem;
        font-weight: 600;
        line-height: 1.125rem;
    }

    .closeContainer {
        position: absolute;
        top: 0.625rem;
        right: 0.75rem;
    }
    .close {
        @include mixins.resetButton;
        position: relative;
        height: 1.375rem;
        width: 1.375rem;
        transform: rotate(45deg);
    }
    .close:before {
        // horizontal line
        content: "";
        background: #ffffff;
        height: 1px;
        position: absolute;
        top: 0.625rem;
        left: 0;
        right: 0;
    }
    .close:after {
        // vertical line
        content: "";
        background: #ffffff;
        width: 1px;
        position: absolute;
        top: 0;
        left: 0.625rem;
        bottom: 0;
    }

    .body,
    .details {
        color: #ffffff;
        line-height: 1.25rem;
    }

    .message {
        display: flex;
        column-gap: 1rem;
        align-items: flex-start;
        padding-bottom: 1.25rem;

        img {
            transform: translateY(0.375rem);
        }
    }

    .detailsActions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #5c656d;
        min-height: 2.625rem;
    }

    .detailsAction {
        color: #c0c0c0;

        &:hover,
        &:focus {
            color: #fff;
            background-color: transparent;
        }
    }

    .showDetails {
        padding-right: 1rem !important;

        button::after {
            @include mixins.dropdown;
            border-color: #fff;
        }

        button.openDetails::after {
            transform: rotate(-135deg);
            top: 40%;
        }
    }

    .copied {
        border-radius: 0.375rem;
        padding: 0.25rem 0.5rem;
        background: #212223;
        align-items: center;
        justify-content: center;
        color: #c0c0c0;
        display: none;
        position: absolute;
        right: $sidePadding;

        &.showCopied {
            display: flex;
        }
    }

    .details {
        position: relative;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        margin-bottom: 0.875rem;
    }

    .details:hover {
        background: #1a1a1a;
    }

    p {
        margin-top: unset !important;
        margin-bottom: 0.5rem;
    }
}

.hiddenInput {
    position: fixed;
    left: -1000px;
    opacity: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

