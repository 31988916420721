.navigation {
    flex-grow: 1;
}

.links {
    padding: 0;
    margin: 0;
    background: var(--header-background-color);
    width: 100%;
    margin-right: 1.875rem;

    & > * {
        margin-right: 1.875rem;
    }
}
