.-titleSemibold {
    font-weight: 600;
}

.container {
    display: flex;
    column-gap: 2.25rem;
}


.logoBox {
    width: 11.875rem;
    height: 6.625rem;
    background-color: #EBF7FF;
    border-radius: 0.19rem;
    text-align: center;

    img {
        height: 100%;
        width: auto;
        object-fit: contain;
    }

    svg {
        height: 100%;
        width: auto;
    }
}

.pageHeader {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr minmax(0, max-content);
    grid-template-rows: auto auto;
    column-gap: 0.75rem;
    min-width: 0;


    > .actions {
        display: flex;
        align-self: start;
        min-height: 2.375rem;
        justify-content: flex-end;
        align-items: center;
        column-gap: 0.75rem;
        flex-wrap: nowrap;
        margin-top: 0.1875rem;
    }

    > .actions.adjacentModalCloseIcon {
        margin-right: 2.75rem;
    }

    > .actions .savedMessage {
        visibility: hidden;
        white-space: nowrap;
        transition: visibility var(--ease);

        &.-visible {
            visibility: visible;
        }
    }
}

.title {
    font-family: "Open Sans", serif;
    font-style: normal;
    font-weight: 700;
    line-height: 2.75rem;
    letter-spacing: -0.1rem;
    color: #000;
    font-size: 2rem;
    overflow-wrap: break-word;
    min-width: 0;
}

.description {
    margin-top: 0.375rem;
    grid-column: 1 / 1;
    &.noMarginTop {
        margin-top: 0;
    }
}

.description_fullLine {
    grid-column: 1 / span 2;
}

.secondLineActions {
    margin-top: 0.375rem;
    display: flex;
    justify-content: right;
    grid-column: 2 / 2;
}