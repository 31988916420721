.container {
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: all;

    .wrapper {
        margin-bottom: 2.5rem;
        margin-right: 1.25rem;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > div {
            position: relative;
        }
    }
}