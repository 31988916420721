@use "../../../stylesheets/mixins";

.header.christmas-theme {
    //background-image: url(/images/Christmas.png);
    background-repeat: repeat-x;
    background-size: 485px 1rem;
    background-position-x: 1rem;
}

.header {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: var(--header-z-index);
    background-color: var(--header-background-color);
    padding-top: 0;
    padding-right: calc(2.25rem + var(--scroll_padding));
    padding-left: 2.25rem;

    @include mixins.remAdaptiveValue(padding-left, 2.5, 1);
    @include mixins.remAdaptiveValue(padding-right, 2.5, 1);

    display: flex;
    align-items: center;

    flex-wrap: nowrap;
    justify-content: flex-start;
}

.icons {
    display: flex;
    justify-content: center;
    margin-left: auto;

    > * {
        margin-right: 1rem;
    }

    > *:last-child {
        margin-right: 0;
    }
}
