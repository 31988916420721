.iconWrapper {
    transform: translateY(3px);
    position: relative;
    &.notViewed::before {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--lightning-yellow);
        right: -6px;
        top: 0;
    }
}

.preview,
.previewCatchEye {
    padding-top: 20px;
    padding-bottom: 18px;
    width: 380px;
    display: flex;

    .iconBox {
        margin-right: 20px;
        margin-left: 25px;
    }

    .textAndLink {
        transform: translateY(-2px);
        max-width: 100%;
        flex-grow: 1;
        margin-right: 25px;
        overflow: hidden;
        a {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
    }

    .text {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .icon {
        flex-shrink: 0;
        width: 1.875rem;
        height: 1.25rem;
        display: flex;
        align-items: center;

        fill: #97a3ab !important;
        &[class*="ADMIN"] {
            width: 1.5rem !important;
            height: 1.5rem !important;
        }
        &[class*="USERS"] {
            width: 1.5rem !important;
            height: 1.5rem !important;
        }
        &[class*="MESSAGES"] {
            fill: #97a3ab !important;

            & [data-lines] {
                fill: white !important;
            }
        }
    }

    .notViewed {
        margin-top: 0.675rem;
        position: relative;

        &::before {
            position: absolute;
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--lightning-yellow);
        }
    }
}

.previewCatchEye {
    pointer-events: all;
    position: absolute;
    top: calc(var(--header-height) + 8px);
    right: 25px;
    box-shadow: 0px 2px 12px rgba(12, 82, 118, 0.13);
    background: var(--white);
    border-radius: 6px;
}

.preview:not(:last-child) {
    border-bottom: 1px solid #ebf5fb;
}

.viewed {
    .notViewed::before {
        background-color: #e0e0e0;
    }
}
