@use "../../../../../scholastic-client-components/src/stylesheets/mixins";

.itemContent {
    display: block;
    box-sizing: border-box;

    border: none;
    width: 100%;

    background: transparent;

    /* inherit font & color from ancestor */
    font: inherit;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    border-radius: 0;

    cursor: pointer;
    font-weight: normal;

    margin: 0;
    padding: 0;

    color: var(--greyish-brown) !important;
    line-height: 2rem;
    text-decoration: none;
    text-transform: none;
    overflow: visible;

    transition: color var(--ease), background-color var(--ease), border-color var(--ease);

    &.matchHeaderColors {
        color: #ffffff !important;
    }

    &.supportLink {
        color: #0076b3 !important;
        &:hover {
            color: var(--regular-red) !important;
        }
    }

    .contentWithDescr {
        justify-content: space-between;
    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        color: var(--warm-grey);
    }

    &.svg_666 svg {
        fill: #666666;
    }

    &:hover, &.active {
        &:not(.matchHeaderColors) :not(.supportLink) {
            background-color: var(--silky);
        }
        text-decoration: none;
        color: var(--regular-red) !important;

        &.matchHeaderColors {
            background-color: #0d4e70 !important;
            color: #ffffff !important;
        }

        svg {
            fill: var(--regular-red);
        }
    }

    &:focus {
        outline: none;
        text-decoration: none;
    }

    &.item-title {
        margin-top: 1rem;
        font-size: 0.75rem;
        color: var(--warm-grey);

        pointer-events: none;

        & .first {
            margin-top: 0;
        }
    }
}

.pointer {
    cursor: pointer;
}

.dropdownTitle {
    font-size: 0.875rem;
    line-height: 2.125rem;
    color: #999999;
    padding: 0 1.875rem;
}

.itemContentOffset {
    display: flex;
    align-items: baseline;
    padding: 0 1.875rem;
    white-space: nowrap;

    &.withIcon {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
            margin-right: 0.625rem;
            height: 1.125rem;
            min-width: 1.125rem;
            width: 1.125rem;
        }
    }

    &.limitedWidth span {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    & .label {
        cursor: pointer !important;
        overflow: hidden;
        flex-grow: 1;
        flex-shrink: 1;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & .description {
        text-transform: none;
        font-size: 0.875rem;
        color: var(--warm-grey);

        overflow: hidden;
        flex-shrink: 100;
        padding-left: 2rem;
        text-align: right;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.medium {
            font-size: 0.8125rem;
        }
    }
}

$blueColor: #0076b3;
$arrow-width: 0.0625rem;

.fullWidthTrigger {
    @include mixins.resetButton;

    background: #eef9ff;
    border-radius: 0.625rem;
    width: 100%;
    height: 2.625rem;
    display: flex;
    align-items: center;
    padding-left: 1.25rem;
    position: relative;
    transition: 0.3s background;

    &:not(.blackTextColor) {
        color: $blueColor;
    }

    * {
        cursor: pointer !important;
    }

    > div {
        left: 0;
        text-align: left;
        white-space: nowrap;
        position: relative;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        color: var(--regular-red);
    }

    &:hover::after {
        border-color: var(--regular-red);
    }

    &::after {
        content: "";
        display: block;

        position: absolute;
        right: 1rem;
        top: 1rem;

        border: solid $blueColor;
        border-width: 0 $arrow-width $arrow-width 0;

        padding: 0.15625rem;
        transform: rotate(45deg);
        transition: 0.3s transform;
    }

    &.redDropdown {
        background: #FFF8F6;
        color: #444444;

        &::after {
            border-color: #444444;
        }
    }

    &.greyTrigger {
        background-color: rgba(255, 248, 246, 1) !important;
        color: rgba(68, 68, 68, 1) !important;
    }

    &.fullWidthTrigger_active {
        color: var(--regular-red);
        background-color: var(--silky);
        transition: 0.3s background;

        &::after {
            border-color: var(--regular-red);
        }

        svg {
            fill: var(--regular-red);
        }
    }

    &.disabled {
        pointer-events: none;
        background-color: #EFEFEF;
        color: #666;

        &::after {
            border-color: #999999;
        }
    }
}

.noPadding {
    padding: 0 !important;
}