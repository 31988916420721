.loader-component {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
        margin-left: 0.625rem;
    }

    &.-small img {
        width: 1.5rem;
    }

    &.inline {
        width: unset;
        padding: 0 0.5rem;

        img {
            height: 0.875rem;
            margin-top: unset !important;
        }
    }

    img {
        &.-animate {
            animation: rotating 1s infinite linear;
        }
    }
}

@keyframes rotating {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}
