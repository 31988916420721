.alertIcon {
    padding: 0 1.375rem;
    width: 100%;
    min-width: 17.5rem;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #FA4E54;
    justify-content: flex-start;

    &.isHomePortal {
        border-radius: 0.625rem;
    }
    .dropdown {
        padding-left: 10px;
        right: 7px;
        &::before, &::after {
            background-color: var(--white);
        }
    }
    span {
        color: var(--white);
    }
}

.preview {
    &.inHeaderPreview {
        width: 3.75rem;
        height: 2.125rem;
    }
    width: 13.75rem;
    height: 7.75rem;
    background-size: cover;
    border-radius: 3px;
}

.headerRightBlock {
    flex-grow: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 10rem;
}

.headerTitle {
    margin-left: 1.125rem;
    margin-right: auto;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown {
    position: relative;
    margin-left: 0.75rem;

    &::before, &::after {
        position: absolute;
        content: "";
        background-color: var(--light-grey-blue);
        width: 6px;
        height: 1px;
    }
    &::before {
        transform: rotate(-45deg);
    }
    &::after {
        transform: translateX(-3.9px) rotate(45deg);
    }
}

.contentWrapper {
    width: 17.5rem;
    padding-top: 1rem;
    max-height: 600px;

    .disabledAllBlock {
        padding: 0.813rem 2rem;
        display: flex;
        justify-content: center;
    }

    .title {
        font-size: .75rem;
        color: var(--light-black);
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
    }

    .previewBox {
        position: relative;
        margin-top: 1rem;
    }
    .alertName {
        margin-top: .75rem;
        color: var(--light-black);
        max-width: 15rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.contentItem {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    &:not(&:last-child) {
        border-bottom: 2px solid #F1F1F1;
    }
    &:not(&:first-child) {
        margin-top: 1.5rem;
    }
}

.disableBtn {
    border: 2px solid #FFF2EF !important;

    & > *:first-child {
        transform: translateY(1px);
    }
}