.dropdownPosition {
    position: absolute;
    visibility: hidden;
    top: -9999;
    left: -9999;
    pointer-events: none;

    &_visible {
        visibility: visible;
        pointer-events: auto;
    }
}
