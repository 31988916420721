@use "../../../../../../scholastic-client-components/src/stylesheets/mixins";
@use "../../adaptive/constants.scss";

.userInfo {
    min-height: 15rem;
    position: relative;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 0.75rem;
    padding: 2.5rem 0.625rem;
    overflow: hidden;

    .educateBox:last-child {
        margin-right: 0;
    }
}

.userName {
    text-align: center;
}

.welcome {
    color: var(--white);
    letter-spacing: -0.05rem;
    line-height: 2.75rem;
    font-weight: bold;

    font-size: 2rem;
    @include mixins.remAdaptiveValue("font-size", 2, 1.25, constants.$homePageBreakPoint);
}

.noDevicesTrialHint {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #D5D4D4;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
}

.noDevicesTitle {
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    margin-bottom: 1rem;
}

.logout {
    position: absolute;
    top: 1.4375rem;
    right: 1.4375rem;

    &:hover {
        span {
            color: white;
        }
    }

    svg {
        @include mixins.remAdaptiveValue("width", 1.5, 1, constants.$homePageBreakPoint);
        @include mixins.remAdaptiveValue("height", 1.5, 1, constants.$homePageBreakPoint);
    }
}
