.container-layout {
    height: 100%;
    padding: var(--container-vertical-padding) var(--container-horizontal-padding) 0;

    &.noPadding {
        padding: 0;
    }

    &.withScroll {
        padding-right: calc(var(--container-horizontal-padding) + var(--scroll_padding));
    }
}

.bottomPaddingBlock {
    height: var(--container-vertical-padding);
}
