.trigger {
    display: flex;
    align-items: center;
    padding-left: 0.25rem;
}

.logo {
    display: flex;
    align-items: center;
    height: var(--header-height);

    svg {
        height: 2rem;
        width: auto;

        vertical-align: middle;
    }
}
