@use "./../../../stylesheets/variables";

.menu {
    padding: 1.25rem 1.0625rem;
    border-radius: 0.375rem;
}

.row {
    display: flex;

    text-align: center;

    @media screen and (max-width: variables.$mobileBreakPoint) {
        justify-content: space-between;
    }
}

.link {
    display: block;
    text-decoration: none !important;
}

$mobileIconWrapperWidth: 5.625rem;

@media screen and (max-width: variables.$mobileBreakPoint) {
    .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        width: $mobileIconWrapperWidth;
        height: $mobileIconWrapperWidth;

        background: rgba(255, 255, 255, 0.06);
        border-radius: 12px;

        &.active {
            background: #ffffff;
        }
    }
}

.icon {
    height: 2.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #687c86;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;

    &.active {
        background-color: var(--lightning-yellow);
    }

    @media screen and (max-width: variables.$mobileBreakPoint) {
        background-color: #e5e7e9;

        &.active {
            background-color: #003a58;
        }
    }
}

.name {
    margin-top: 10px;
    font-size: 0.875rem;
    text-decoration: none !important;
    color: #444444;

    @media screen and (max-width: variables.$mobileBreakPoint) {
        color: #ced8e2;
    }
}

.portalContainer {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;

    text-align: center;
    padding: 0.938rem 1rem 0.688rem;
    font-size: 0.75rem;
    line-height: 1.33;
    border-radius: 5px;
    width: 5.75rem;

    &.active {
        background-color: var(--silky);
    }

    &.miniPortal {
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: var(--header-links-color);
    }

    &.miniPortal .icon {
        transform: scale(0.7);
        background-color: var(--header-links-color);
    }

    &:not(.active):hover {
        .icon {
            background-color: #0076b3;
        }

        .name {
            color: #0076b3;
        }
    }

    @media screen and (max-width: variables.$mobileBreakPoint) {
        padding: 0;
        height: 7.375rem;
        justify-content: space-between;

        &.active {
            background-color: transparent;
        }
    }
}

.mobileGap {
    width: $mobileIconWrapperWidth;
}

.popup {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(12, 82, 118, 0.13);
    border-radius: 0.375rem;
}