@use "../../stylesheets/mixins";

$loaderWidth: 64px;

.focus__disableEvents {
    pointer-events: none;
}

.icon {
    &.icon-delete,
    &.icon-edit {
        transform: translateY(0.0625rem);
    }
}

.iconBox {
    display: flex;
    justify-content: center;
    align-content: center;

    &.-margin {
        margin-right: 0.563rem;
    }
}

.icon-customIcon.-margin {
    margin-right: 0.5rem;
}


/* MonochromeIcon base styles */
.monochromeIconBox {
    display: flex;
    justify-content: center;
    align-content: center;

    &.-margin {
        margin-right: 0.5rem;
    }
}

.monochromeIconBox svg {
    height: .75rem;
    width: auto;
    fill: #303030;
}

.-disabled {
    &.icon:not(.icon-selectAll,.icon-setting,.icon-reset,.icon-detach) {
        stroke: #9999a6;
    }
}

.button-component.reset {
    .iconBox {
        transform: translateY(0.0625rem);
    }

    .iconBox.-margin {
        margin-right: 0.5rem;
    }
}

.focus-manager {
    outline: none;
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
    position: relative;

    &.width100percent {
        width: 100%;

        .button-component {
            width: 100%;
        }
    }
}

.dropBtnStyles {
    @include mixins.resetButton;
}

.button-component {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0.188rem 1.25rem 0.375rem;
    background: none;
    border-radius: 0.188rem;
    font: normal var(--font-size) var(--font-stack);
    border: 2px solid;
    line-height: 1.188rem;
    border-color: var(--light-blue-gray);
    cursor: pointer;
    z-index: 1;
    color: var(--greyish-brown);
    height: 100%;

    &, &:disabled {
        transition: color var(--ease), background-color var(--ease), border-color var(--ease);
    }

    &.correctLineHeight {
        line-height: 1.125rem;
    }

    > .content {
        * {
            cursor: pointer;
        }
    }

    &.-whiteStyles {
        background: none;
        border-color: var(--light-blue-gray);

        &:hover {
            border-color: var(--light-blue-gray);
        }

        &.-active {
            border-color: transparent;
            background-color: #e5f4fd;
        }
    }

    &.-uploading {
        position: relative;
        z-index: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: default;
        padding: 0.375rem 2rem 0.4375rem;

        &::before {
            content: "";
            position: absolute;
            z-index: -2;
            width: 200%;
            height: 650%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: 100% 100%;
            background-image: linear-gradient(
                    0deg,
                    rgba(58, 188, 255, 1) 0%,
                    rgba(213, 115, 209, 1) 39%,
                    rgba(255, 200, 6, 1) 61%,
                    rgba(255, 200, 6, 0.5) 100%
            );
            animation: rotate 2s linear infinite;
        }

        &::after {
            content: "";
            top: 0.125rem;
            left: 0.15rem;
            position: absolute;
            z-index: -1;
            width: calc(100% - 0.3125rem);
            height: calc(100% - 0.25rem);
            background: white;
            border-radius: 0.15rem;
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(1turn);
        }
    }

    &.-loader {
        pointer-events: none;
    }

    &.-text {
        outline: none;
        color: #0076b3;
        padding: 0;
        border: none;

        .icon-question,
        {
            fill: var(--ocean-blue);
        }

        .icon-customIcon {
            @include mixins.svgColors(#43A6D9);

            svg {
                height: 1.125rem;
                width: auto;
            }

            svg * {
                transition: all var(--ease);
            }
        }

        .monochromeIconBox svg {
            height: 1.125rem;
            width: auto;
            transition: fill var(--ease);
            fill: #43A6D9;
        }

        &.-dropdown > .content::after {
            border-color: #43A6D9;
        }

        .icon-unlimited,
        .icon-calendar,
        .icon-screensNotAssign,
        .icon-selectAll        {
            fill: #43A6D9;
        }
    }

    &.-color-ocean-blue {
        color: var(--ocean-blue);
    }

    &.-color-success {
        color: var(--grassy-green);
    }

    &.-color-warning {
        color: var(--yellow);
    }

    &.-color-error {
        color: var(--cinnabar);
    }

    &.-color-regular-red {
        color: var(--regular-red);
    }

    &.-color-warm-grey {
        color: var(--warm-grey);
    }

    &.-color-light-black span {
        color: var(--light-black);
    }

    &.-color-white {
        color: var(--white);
    }

    &.-text.-color-orange {
        color: rgb(211, 184, 127);
    }

    &.-loader.-text {
        animation-name: textInProgress;
        animation-duration: 1200ms;
        animation-iteration-count: infinite;
    }

    .icon-customIcon {
        @include mixins.svgColors(var(--blue));
    }

    &.-text:not(.noHoverEffect):hover,
    &.-text:not(.noHoverEffect):focus {
        color: var(--regular-red);

        .icon-update,
        .icon-export,
        .icon-list,
        .icon-revoke,
        .icon-activate,
        .icon-arrow,
        .icon-screensNotAssign,
        .icon-edit,
        .icon-selectAll,
        .icon-setting,
        .icon-upload,
        .icon-screenWithPlus,
        .icon-rename,
        .icon-delete,
        .icon-history,
        .icon-clockArrow,
        .icon-folder_plus_sign,
        .icon-content_plus_sign,
        .icon-question,
        .icon-CREATIVE,
        .icon-unlimited,
        .icon-search,
        .icon-copyEntity,
        .icon-transparentCopy,
        .icon-file-log,
        .icon-add_network,
        .icon-calendar,
        .icon-content_sign,
        .icon-search_with_border,
        .icon-reset,
        .icon-detach {
            fill: var(--regular-red);
        }

        .icon-exit {
            stroke: white;
        }

        .icon-copySign {
            stroke: var(--regular-red);
        }

        .icon-customIcon {
            @include mixins.svgColors(var(--regular-red));
        }

        .icon-customIcon svg {
            fill: var(--regular-red);
        }

        .monochromeIconBox svg {
            fill: var(--regular-red);
        }

        .icon-bulk,
        .icon-revoke,
        .icon-map {
            stroke: var(--regular-red);
        }

        .icon-copy {
            stroke: var(--regular-red);
            fill: white;
        }

        &.-dropdown > .content::after {
            border-color: var(--regular-red);
        }
    }

    &.-delete.-text:hover {
        text-decoration: none;
        color: var(--regular-red);
    }

    > .content {
        font-family: inherit;
        font-size: inherit;
        color: inherit;

        position: relative;
        z-index: 1;
        width: 100%;
        white-space: nowrap;
    }

    &:disabled {
        .icon-customIcon {
            @include mixins.svgColors(var(--warm-grey));
        }

        .monochromeIconBox svg {
            fill: var(--warm-grey);
        }

        .icon-edit {
            stroke: none;
            fill: #9999a6;
        }

        .icon-CREATIVE,
        .icon-question,
        .icon-setting,
        .icon-selectAll,
        .icon-history,
        .icon-update,
        .icon-unlimited,
        .icon-file-log,
        .icon-calendar,
        .icon-reset,
        .icon-detach {
            fill: var(--warm-grey) !important;
        }

        .icon-delete,
        .icon-folder_plus_sign,
        .icon-content_plus_sign,
        .icon-rename {
            fill: var(--warm-grey);
            stroke: none;
        }

        .icon-bulk {
            stroke: var(--warm-grey);
        }
    }

    &.-edit,
    &.-delete,
    &.-reset,
    &.-add,
    &.-view,
    &.-preview,
    &.-selectAll,
    &.-detach,
    &.-revoke {
        padding-left: 0.3rem;
        padding-right: 0.35rem;

        > .content {
            display: flex;
            align-items: center;
        }

        > .content::before {
            content: "";
            display: inline-block;
            background-repeat: no-repeat;
            width: 1rem;
            height: 1rem;
            margin-right: 0.3rem;
        }
    }

    &.-edit {
        > .content::before {
            width: 1.1rem;
            height: 1.1rem;
            transform: translateY(-0.05rem);
        }
    }

    &.-icon {
        display: flex;
        align-items: center;
    }

    &.-icon-black {
        fill: #444444;
    }

    &.-icon-lightBlue {
        svg {
            fill: #44a6d9;
        }
    }

    &.-icon-cinnabar {
        svg {
            fill: var(--cinnabar);
        }
    }

    .iconContainer {
        padding-right: 10px;
        color: #43a6d9;
    }

    &.-delete.-loader > .content::before {
        background-image: url("../../graphics/Loader.svg");
        background-size: contain;
    }

    &.-right-icon {
        flex-direction: row-reverse;

        .icon-arrow {
            transform: rotate(180deg);
            margin-left: 0.625rem;
        }

        .icon-doubleArrow {
            transform: rotate(180deg);
        }

        .icon-list {
            position: relative;
            top: 0.05rem;
            margin-left: 0.625rem;
        }
    }

    &.detach {
        color: var(--cinnabar);

        .icon-detach {
            fill: var(--cinnabar);
        }
    }

    &.-remove {
        border-color: var(--light-red);
        color: var(--red-orange);

        &:hover {
            border-color: var(--zinnwaldite-pink) !important;
        }

        &:active {
            border-color: var(--light-red) !important;
            background-color: var(--light-red) !important;
        }
    }

    &.-primary {
        background-color: var(--yellow);
        border-color: var(--yellow);
    }

    &.-primary:disabled:not(.-loader) {
        background-color: var(--gallery);
        border-color: var(--gallery);
    }

    &.-danger {
        background: var(--danger-red);
        border-color: var(--danger-red);
        color: var(--white);

        &:hover {
            border-color: var(--carrot-colour);
        }

        &:active {
            border-color: var(--salmon);
            background-color: var(--salmon);
        }

        &.-text {
            background: unset;
            border-color: unset;
            color: var(--danger-red);

            svg {
                fill: #FE8A65;
            }
        }
    }

    &.-confirmDanger:not(:disabled) {
        background: var(--confirm-red);
        border-color: var(--confirm-red);
        color: var(--white);

        &:hover {
            border-color: var(--carrot-colour);
        }

        &.-loader {
            background: transparent;
            border-color: var(--smoky-white);
            color: var(--warm-grey);
        }

        &:active {
            border-color: var(--salmon);
            background-color: var(--salmon);
        }

        &.-text {
            background: unset;
            border-color: unset;
            color: var(--danger-red);
        }
    }

    &.-orange:not(.-loader) {
        background-color: var(--scandal-orange);
        border-color: var(--scandal-orange);
    }

    &.danger-text {
        .content {
            color: #e50000;
        }
    }

    &.-loader.-danger {
        color: var(--warm-grey);
    }

    &.-active {
        z-index: 2;
    }

    &.-primary:active:not(.-danger):not(.-text):not(.-close):not(.-remove):not(.-whiteStyles),
    &.-primary.-active:not(.-danger):not(.-text):not(.-close):not(.-remove):not(.-whiteStyles) {
        background-color: var(--light-yellow);
        border-color: var(--light-yellow);
    }

    &:disabled:not(.-loader):not(.ignoreDisable) {
        pointer-events: none;
        color: var(--warm-grey);
        border-color: var(--smoky-white);
    }

    &.-primary {
        &:hover {
            &:not(:active):not(.-active):not(:disabled) {
                border-color: var(--ocre);
            }
        }
    }

    &:not(.-text):not(.cloud) {
        &:active:hover,
        &.-active:hover {
            background-color: var(--foam);
            border-color: var(--foam);
        }
    }

    &:not(.noHoverEffect):hover {
        border-color: #cfe2ed;
        transition: none;
    }

    &:focus {
        outline: none;
    }

    &.close {
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        cursor: pointer;

        width: 24px;
        height: 24px;
        mask-image: url(../../graphics/CloseIcon.svg);
        background-color: #bdced9;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transition: none;

        &:hover {
            background-color: #88a4b7;
        }
    }

    &.closeDropdown {
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        cursor: pointer;

        width: 24px;
        height: 24px;
        object-fit: contain;
        background: transparent url(../../graphics/CloseIconDropdown.svg) no-repeat center center;
        background-size: contain;
    }

    &.-editCreative {
        background-color: #fff3fc;
        padding: 0.3125rem 0.625rem;
        color: #c646a2;
        border-radius: 0.188rem;
        line-height: 129%;
        font-size: 0.875rem;

        &:disabled {
            background-color: #EFEFEF;
            padding: 0.3125rem 0.625rem;
            border-radius: 0.1875rem;

            .content {
                color: #666666;
            }
        }

    }

    > .loader {
        @include mixins.buttonLoader(var(--light-blue-gray), var(--white));
    }

    &.-primary > .loader {
        @include mixins.buttonLoader(var(--yellow), var(--white));
    }

    &.-danger > .loader {
        @include mixins.buttonLoader(var(--danger-red), var(--red));
    }

    &.-dropdown > .content {
        padding-right: 1rem;

        &::after {
            @include mixins.dropdown;
            transition: 0.3s transform;
            right: 0.11rem;
        }
    }

    &.-dropdown-top-pos.-dropdown > .content {
        &::after {
            transform: rotate(225deg) translateY(-4px);
        }
    }

    &.-dropdown.-blue-dropdown .content {
        &::after {
            border-color: #0076b3;
        }

        &:hover::after {
            border-color: var(--regular-red);
        }
    }

    &.-dropdown.-red-dropdown .content {
        &::after {
            border-color: var(--cinnabar);
        }
    }

    &:disabled .content::after {
        border-color: var(--warm-grey) !important;
    }

    &.-dropdown.-text {
        padding-right: 0.5rem;
    }

    &.-border-blue {
        border-color: var(--light-blue-gray) !important;
        background-color: transparent !important;
    }

    &.-border-red {
        border-color: var(--light-red);
        background-color: white !important;
    }

    &.preventClick {
        text-decoration: none !important;
        cursor: default !important;
        color: var(--greyish-brown);
    }

    .icon-unlimited {
        position: relative;
        top: 0.0625rem;
    }

    @keyframes textInProgress {
        50% {
            opacity: 0.5;
        }
    }

    &.cloud {
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        transition: color var(--ease);
        outline: none;
        border: none;

        &.light-blue {
            background: #EEF9FF;
            color: #0076B3;

            .monochromeIconBox svg {
                fill: #43A6D9;
            }
        }

        .monochromeIconBox svg {
            height: 1.125rem;
            width: auto;
            transition: fill var(--ease);
        }

        &:hover, &:focus {
            color: #B06A00;

            .monochromeIconBox svg {
                fill: #B06A00;
            }
        }

        &:disabled {
            background-color: #EFEFEF;
            color: #666666;
        }
    }
}

.limitedWidth {
    &,
    button {
        max-width: 100%;
    }

    .content {
        display: block;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

.focus-manager:focus > .button-component:not(:disabled) {
    border-color: var(--light-yellow);
    transition: none;

    &.-primary {
        &:not(:active):not(.-active):not(:disabled) {
            border-color: var(--ocre);
        }
    }

    &.-remove {
        border-color: var(--zinnwaldite-pink);
    }

    &.-text {
        color: var(--regular-red);

        .icon-customIcon svg {
            fill: var(--regular-red);
        }

        .monochromeIconBox svg {
            fill: var(--regular-red);
        }

        &.-dropdown > .content::after {
            border-color: var(--regular-red);
            transition: border-color var(--ease);
        }
    }

    &.-danger {
        border-color: var(--carrot-colour);
    }

    &.-closeDropdown {
        outline: 0;
        box-shadow: none;
    }

    &.-close {
        outline: 0;
        box-shadow: none;
    }

    &.-confirmDanger {
        border-color: var(--carrot-colour);
    }

    &.-delete.-text {
        color: var(--regular-red);
        text-decoration: none;
    }

    .icon:not(.icon-detach):not(.icon-export):not(.icon-videoPlay):not(.icon-rename) {
        stroke: var(--regular-red);
        fill: var(--regular-red);
    }

    .icon-update {
        & svg {
            fill: var(--regular-red) !important;
        }

        stroke: none !important;
    }

    .icon-export {
        fill: var(--regular-red);
    }

    .icon-revoke {
        fill: var(--regular-red);
    }

    .icon-add,
    .icon-edit {
        stroke: none;
    }

    .icon-copy {
        stroke: var(--regular-red) !important;
        fill: white !important;
    }

    .icon-activate {
        fill: var(--regular-red) !important;
        stroke: none !important;
    }

    .icon-arrow {
        fill: var(--regular-red);
    }

    .icon-view,
    .icon-delete {
        fill: none;
    }

    .icon-copy {
        fill: white;
    }

    .icon-upload {
        stroke: none;
    }

    .disabled {
        border-color: black !important;
    }
}

.backlight.-text {
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -0.2813rem;
        bottom: -0.21875rem;
        left: -0.5rem;
        right: -1rem;
        height: 1.87500rem;
        background-color: #E5F4FD;
        border-radius: .3125rem;
    }

    &.arrow-right:before {
        clip-path: polygon(0% 50%, 0.625rem 0%, 100% 0%, 100% 100%, 0.625rem 100%);
        transform: scaleX(-1);
    }

    &.background:before {
        left: -0.75rem;
        right: -0.75rem;
    }
}

.backlight.background:not(.text) {
    background-color: #E5F4FD;
    border-color: #E5F4FD;
}
