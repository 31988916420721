.box-component {
    &.-flex {
        display: flex;

        &.-ai_center {
            align-items: center;
        }

        &.-ai_bottom {
            align-items: flex-end;
        }

        &.-ai_top {
            align-items: flex-start;
        }

        &.-ai_baseline {
            align-items: baseline;
        }

        &.-ai_stretch {
            align-items: stretch;
        }

        &.-ai_end {
            align-items: flex-end;
        }

        &.-jc_space-between {
            justify-content: space-between;
        }

        &.-jc_right {
            justify-content: flex-end;
        }

        &.-jc_left {
            justify-content: flex-start;
        }

        &.-jc_center {
            justify-content: center;
        }
        &.column {
            flex-direction: column;
        }
    }
}
