@use "scholastic-client-components/src/stylesheets/index";
@import "root";

html {
    font-size: 16px;
    margin-top: 0 !important;
    body {
        min-width: auto;
        overflow-y: unset;
    }
}

h1 {
    margin: 0;
}
