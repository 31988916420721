.navigation-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--header-height);
    width: 100%;

    color: var(--light-grey-blue) !important;
    background-color: transparent;
    border-bottom: 0.25rem solid transparent;
    outline: none;

    &:focus,
    &:hover {
        color: var(--link-water) !important;
        text-decoration: none;
    }

    &.-active {
        color: var(--white) !important;
        border-bottom-color: var(--yellow);
    }

    span {
        position: relative;
        top: 0.12rem;
    }
}
