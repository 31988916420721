.supportItem {
    border-top: 1px solid #EBF5FB;
    min-width: 18.75rem  /* 300/16 */;
    padding: 16px 30px 18px;

    &.seeAlsoLinksContainer {
        padding-bottom: 16px;
    }
    &.mainLinksContainer {
        border-top: none;
    }

    &.lastItem {
        padding-bottom: 8px;
    }

    .supportItemTitle {
        &.mainLinks {
            font-size: 0.8125rem;
            text-transform: uppercase;
            letter-spacing: 0.16rem;
            line-height: 18px;
            padding-bottom: 14px;
        }

        &.withoutItems {
            padding-bottom: 7px;
        }

        &.seeAlsoLinks {
            line-height: 32px;
            color: #444444;
        }
    }

    .link {
        transition: color 0.3s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 32px;

        &.defaultItems {
            line-height: 18px;
        }

        a {
            text-decoration: none;
            color: #0076B3;
        }

        a:hover, a:focus {
            color: #b06a00;
            transition: color 0.3s;
        }
    }
}