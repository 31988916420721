.input-time-picker {
    &.-with-second {
        .time-picker.rc-time-picker {
            width: 6.5625rem;
        }
    }

    &.-with-am-pm-label {
        position: relative;
        width: 5rem;

        .am-pm {
            position: absolute;
            font-size: 0.75rem;
            color: var(--warm-grey);
            bottom: 0.5rem;
            z-index: 10;
        }
    }
}

.time-picker.rc-time-picker {
    width: 4.375rem;
    border-bottom: 2px solid #e4eef4;
    display: flex;
    align-items: center;
    transition: 0.3s border-bottom;

    input {
        color: #444444;
    }

    &:hover {
        border-bottom-color: #CFE2ED;
    }

    &.disabled {
        border-bottom-color: #EFEFEF;
    }

    &.open-time-picker {
        border-bottom: 2px solid #ffc719;
    }

    &.error-time-picker {
        border-bottom: 2px solid #FE8A65;
    }

    .rc-time-picker-input {
        border: none;
        outline: none;
        font-family: var(--font-stack);
        font-size: 0.875rem;
        padding: 0;
        height: 2.125rem;
    }

    .rc-time-picker-clear {
        display: none;
    }

    .time-picker-clear {
        position: absolute;
        right: 0;
        bottom: 0.3125rem;
        cursor: pointer;

        svg {
            stroke: #999999;
        }
    }

    &.right {
        .rc-time-picker-input {
            cursor: pointer !important;
            text-align: right;
        }
        .rc-time-picker-input[disabled] {
            cursor: default !important;
            color: #999999;
        }

        .time-picker-clear {
            left: -1.5625rem;
        }
    }

    &.onClear {
        .time-picker-clear {
            display: none;
        }
    }

    &.fillWidth {
        width: 100% !important;
    }
}

.time-picker-popup.rc-time-picker-panel {
    padding-top: 2.7rem;
    width: auto;
    z-index: 1070;

    &:not(.am-pm-panel) {
        .rc-time-picker-panel-inner {
            padding-top: 0.625rem;
        }
    }

    &.align-center {
        .rc-time-picker-panel-inner {
            left: -50%;
            transform: translateX(25%);
        }
    }

    .rc-time-picker-panel-inner {
        border: 1px solid var(--light-blue-gray-2);
        box-shadow: 0 2px 8px 0 rgba(0, 58, 88, 0.1);
        width: 100%;
        position: relative;
        padding-top: 2.75rem;
        margin-bottom: 2rem;
        border-radius: 6px;
        overflow: hidden;

        .rc-time-picker-panel-input-wrap {
            display: none;
        }
    }

    .rc-time-picker-panel-select {
        padding: 0 0 0.625rem;
        margin: 0;
        transition: all 500ms ease;
        width: 4rem;
        overflow-x: hidden;
        overflow-y: overlay;
        max-height: 13.75rem;



        &::-webkit-scrollbar {
            transition: all 500ms ease;
            width: 0.313rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a6a6a630;
            transition: all 500ms ease;
            border-radius: 4px;
            max-height: 10%;
        }

        li {
            width: 100%;
            position: relative;
            height: 2.125rem;
            text-align: center;
            outline: none;
            padding: 0;
            line-height: 2.125rem;

            &:hover:not(.rc-time-picker-panel-select-option-selected) {
                background-color: var(--silky);
                color: var(--regular-red);
            }

            &.rc-time-picker-panel-select-option-selected {
                background-color: #eef9ff;
                font-weight: normal;
            }
        }
    }

    &.am-pm-panel {
        .rc-time-picker-panel-select {
            &:last-child {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                height: 2.125rem;
                border-left: none;
                border-bottom: 1px solid #ebf5fb;
                padding: 0px;
                overflow: hidden;

                ul {
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    li {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        height: 100%;
                        text-transform: uppercase;
                        color: var(--ocean-blue);

                        &:hover {
                            background-color: white;
                            color: var(--regular-red);
                        }

                        &.rc-time-picker-panel-select-option-selected {
                            background-color: #7f8694;
                            color: white;

                            &:hover {
                                color: white;
                            }
                        }

                        &:first-child {
                            &::before {
                                content: url("../../../graphics/AMIconActive.svg");
                                width: .75rem;
                                transform: translateY(1px);
                                display: block;
                                margin-right: 0.25rem;
                            }

                            &.rc-time-picker-panel-select-option-selected::before {
                                content: url("../../../graphics/AMIcon.svg");
                            }
                            &:focus::before {
                                content: url("../../../graphics/AMIcon.svg");
                            }
                            &:hover:not(.rc-time-picker-panel-select-option-selected)::before {
                                content: url("../../../graphics/AMIconHover.svg");
                            }
                        }

                        &:last-child {
                            &::before {
                                content: url("../../../graphics/PMIconActive.svg");
                                width: .75rem;
                                transform: translateY(1px);
                                display: block;
                                margin-right: 0.25rem;
                            }

                            &.rc-time-picker-panel-select-option-selected::before {
                                content: url("../../../graphics/PMIcon.svg");
                            }
                            &:focus::before {
                                content: url("../../../graphics/PMIcon.svg");
                            }
                            &:hover:not(.rc-time-picker-panel-select-option-selected)::before {
                                content: url("../../../graphics/PMIconHover.svg");
                            }
                        }
                    }
                }
            }
        }
    }

    .rc-time-picker-panel-select-active {
        &::-webkit-scrollbar-thumb {
            transition: all 500ms ease;
        }
    }

    &.rc-time-picker-panel-narrow {
        max-width: unset;
    }
}

.rc-time-picker-input[disabled] {
    background-color: unset !important;
    cursor: default !important;
}
