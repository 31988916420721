.ant-picker-panel {
    border: 5px solid #f2faff;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
}

.ant-picker-cell-disabled::before {
    background: #f8f8f8;
    border-radius: 2px;
    width: 30px;
    height: 30px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
}

.calendar tbody {
    display: grid;
    row-gap: 1px;
}

.calendar.calendar-all-selected .ant-picker-cell-inner {
    cursor: default !important;
    background-color: #f8f8f8 !important;
    color: var(--black) !important;
}

.calendar tbody tr {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 1px;

    td {
        width: 30px;
        height: 30px;
        padding: 0;

        .ant-picker-cell .ant-picker-cell-end .ant-picker-cell-inner {
            border-radius: 3px !important;
        }

        .ant-picker-cell-inner {
            line-height: 30px;
            width: 30px;
            height: 30px;
        }
    }
}

.calendar thead tr {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 1px;

    th {
        padding: 0;
    }
}
