@use "./../../../stylesheets/variables";

.notifications-layout {
    display: flex;
    flex-direction: column-reverse;
    right: var(--container-horizontal-padding);
    bottom: var(--container-vertical-padding);
    z-index: var(--notifications-z-index);

    @media screen and (max-width: variables.$mobileBreakPoint) {
        right: var(--mobile-container-hor-padding);
    }
}
