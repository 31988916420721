@use "../../../stylesheets/mixins";

.dropdown {
    height: var(--header-height);

    outline: none;
    background: transparent no-repeat 0 center;

    font: inherit;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    color: var(--light-grey-blue);
    text-decoration: none;

    padding: 0.5rem;

    border: none;
    border-radius: 0;
    border-bottom: 0.25rem solid transparent;

    cursor: pointer;

    &.-fullHeightDropdown {
        padding: 0;
        border: 0;
        > .content {
            height: 100%;
            top: 0;
        }
    }

    &.link {
        width: 100%;
    }

    > .content {
        display: inline-block;
        padding-right: 1rem;
        position: relative;
        color: inherit;
        top: 0.12rem;

        &:not(.icon)::after {
            @include mixins.dropdown;
            border-color: var(--light-grey-blue);
            top: 30%;
        }

        &.icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: unset;
        }
    }

    &:active,
    &.-active {
        background: transparent no-repeat 0 center;
    }

    &.-active {
        color: var(--white);
        border-bottom-color: var(--yellow);

        > .content::after {
            border-color: var(--white);
        }
    }

    &.-open {
        color: var(--white);

        > .content::after {
            border-color: var(--yellow);
        }
    }

    &.-open:not(.hideYellowBorder) {
        border-bottom-color: var(--yellow);
    }

    &.-yellow {
        color: var(--yellow);

        > .content::after {
            border-color: var(--yellow);
        }
    }

    &:hover {
        &:not(.-active) {
            text-decoration: none;
        }
    }

    &:focus {
        outline: none;
    }
}

.popup {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(12, 82, 118, 0.13);
    border-radius: 0.375rem;
}
