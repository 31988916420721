._18px_999999,
._18px_444444{
    width: 1.125rem;
    transform: translateY(0.0625rem);
    flex-shrink: 0;
    vertical-align: text-top;
}

._18px_999999 {
    fill: #999999;
}

._18px_444444 {
    fill: #444444;
}

.institution,
.building {
    transform: unset;
}

.business {
    transform: translateY(-0.0625rem);
}