.h1-component,
.h2-component,
.h3-component,
.h4-component {
    margin: 0;
    cursor: default;
    &.white {
        color: var(--white);
    }

    &.disabled {
        color: var(--warm-grey);
    }

    &.semibold {
        font-weight: 600;
    }
}

.h1-component {
    font-size: 2rem;
    letter-spacing: -0.05em;
    line-height: 2.75rem;
    color: #222222;
    font-weight: bold;

    &.red {
        color: #FE7145;
    }
}

.h2-component {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: bold;
    &.semibold {
        font-weight: 600;
    }
}

.h3-component {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
}

.h4-component {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.14;
    letter-spacing: 0.125rem;
    text-transform: uppercase;

    &.semibold {
        font-weight: 600;
    }
}
