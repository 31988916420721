$horTranslate: 50px;
$verTranslate: 250px;

.wrapper {
    &.opacity {
        transition-timing-function: ease-out;
        z-index: 10; //https://developer.mozilla.org/ru/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
        transition-property: opacity;
        opacity: 0;
    }
    &:not(.opacity) > * {
        transition-duration: inherit;
        transition-property: transform;
        transition-timing-function: ease-out;
    }

    &.toRight {
        &:not(.mounted) > * {
            transform: translateX(calc(0px - 100% - #{$horTranslate}));
        }
    }
    &.toLeft {
        &:not(.mounted) > * {
            transform: translateX(calc(100% + #{$horTranslate}));
        }
    }
    &.toTop {
        &:not(.mounted) > * {
            transform: translateY(calc(0px + #{$verTranslate}));
        }
    }
    &.toBottom {
        &:not(.mounted) > * {
            transform: translateY(calc(0px - #{$verTranslate}));
        }
    }

    &.mounted {
        opacity: 1;
    }
}

