@use "./../../stylesheets/mixins";

.popup {
    position: absolute;
    bottom: 1.25rem;
    right: 1.25rem;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 0.375rem;
    padding: 1.3125rem 1.5rem 1.1875rem 1.625rem;
    color: white;
    display: flex;
    align-items: baseline;
    z-index: 1100;
    min-width: 19.6875rem;
    column-gap: 0.625rem;
}

.popup.grey {
    background-color: #4C545B;
}

.icon {
    fill: white;
}

.crudIcon {
    transform: translateY(0.1875rem);
}

.textBox {
    max-width: 31.25rem;
    word-break: break-word;
    transform: translateY(-0.0625rem);
}

.closeButton {
    @include mixins.resetButton;

    transform: translateY(-0.1875rem);
    margin-left: auto;
    width: 1rem;
}