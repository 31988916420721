.dropdownRoot {
    position: relative;
}

.container {
    max-width: 100%;
}

.triggerWrapper {
    display: inline-block;
    max-width: 100%;
}

.triggerLayout {
    position: absolute;
    pointer-events: none;
}
