@use "../../../../../stylesheets/mixins";

.navLink {
    display: flex;
    align-items: center;
    height: 2.5rem;
    border-radius: 0.375rem;
    padding: 0 1.25rem;
    color: #ffffff;

    @include mixins.remAdaptiveValue(font-size, 1.2, 0.875);
    @include mixins.remAdaptiveValue(height, 4, 2.5);

    &_orange {
        background-color: #FFD05E;
    }

    &_active {
        background-color: #004366;
    }
}

