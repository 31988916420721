.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
}

.ant-picker {
    border: none;
    padding: 0;
    cursor: pointer;
    position: relative;
    flex: 1 0 auto;

    &.fardp {
        input:not(.adjusted-width) {
            transition: none;
        }
    }

    &.fardpWithResetIcon {
        input:not(.adjusted-width) {
            transition: none;
        }
    }

    &.ant-picker-focused {
        box-shadow: none;
    }

    &.ant-picker-disabled {
        background: none;
        cursor: default;
    }
}

.ant-picker-cell-in-view,
.ant-picker-cell-in-range,
.ant-picker-cell-range-end,
.ant-picker-cell-range-start {
    .weekend {
        opacity: 1;
        color: var(--moderate-orange);
    }
}

.weekend {
    opacity: 0.7;
    color: var(--moderate-orange);
}

.datePicker.noToday {
    .ant-picker-today-btn {
        display: none;
    }

    .ant-picker-footer-extra {
        margin-left: 0;
    }
}

.datePicker.no-footer {
    .ant-picker-footer {
        display: none;
    }
}

.ant-picker-suffix,
.ant-picker-clear {
    display: none;
}

.ant-picker-dropdown {
    z-index: 1100;
}

.ant-picker-panel-container {
    box-shadow: 0px 0.125rem 0.75rem rgba(12, 82, 118, 0.13);
    border-radius: 0.375rem;
}

.ant-picker-header {
    padding: 0 1.6875rem;
    border-bottom-color: #ebf5fb;

    > * {
        line-height: 4rem;
    }

    button {
        &:hover {
            span::before,
            span::after {
                border-color: var(--ocean-blue);
            }
        }
    }
}

.ant-picker-header-view {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 3.5rem;
    pointer-events: none;
}

.ant-picker-date-panel {
    width: 16.25rem;

    .ant-picker-content {
        width: 14rem;

        .ant-picker-cell-disabled > .ant-picker-cell-inner {
            color: rgba(153, 153, 153, 1);
            --calendar-color: rgba(153, 153, 153, 1);
        }

        tr {
            &:not(:first-child) {
                td:not(.ant-picker-cell-in-view) {
                    display: none;
                }
            }

            td.ant-picker-cell-in-view ~ td {
                display: flex !important;
            }
        }

        th {
            color: var(--warm-grey);

            &:nth-child(6),
            &:nth-child(7) {
                color: var(--moderate-orange);
            }
        }
    }
}

.ant-picker-cell-in-range,
.ant-picker-cell-range-end,
.ant-picker-cell-range-start {
    .ant-picker-cell-inner {
        background-color: #6792ff;
    }
}

.ant-picker-cell-in-view {
    color: var(--greyish-brown);
}

.ant-picker-footer {
    border-top-color: #ebf5fb;
    height: 3rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    padding: 0 1.875rem;
}

.datePicker .ant-picker-footer-extra {
    border-bottom: none;
    padding: 0;
    line-height: 1;
    margin-right: -4px;
    margin-left: auto;
}

.ant-picker-today-btn {
    color: var(--ocean-blue);
    font-size: 0.875rem;

    &:hover {
        color: var(--regular-red);
    }
}

.ant-picker-date-panel {
    thead th,
    tbody td {
        padding: 0px;
        margin: 0px;
        overflow: hidden;
        width: 32px !important;
        height: 32px !important;
    }
}

.ant-picker-month-panel,
.ant-picker-year-panel {
    width: 260px;

    tbody {
        row-gap: 10px !important;

        tr {
            display: flex;
        }
    }

    .ant-picker-content {
        height: auto;
    }

    .ant-picker-cell-inner {
        line-height: 2.625rem !important;
        width: 4.375rem !important;
        height: 2.625rem !important;
    }
}

.ant-picker-decade-panel {
    width: 15.625rem;

    tbody {
        row-gap: 10px !important;

        tr {
            display: flex;
        }
    }

    .ant-picker-content {
        height: auto;
    }

    .ant-picker-cell-inner {
        padding: 0px 5px;
    }

    .ant-picker-cell-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1.5em !important;
        width: 4.375rem !important;
        height: 3rem !important;
    }
}

.ant-picker-body.ant-picker-body {
    padding: 0.8125rem 1.3125rem;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
.ant-picker-cell-inner {
    color: #B06A00;
    opacity: 1;
    background: #fff4d9;
}

.datePicker .ant-picker-cell-selected .ant-picker-cell-inner {
    background: #1890ff !important;
}

.datePicker tbody {
    min-height: 80%;
    display: grid;
    row-gap: 4px;

    .ant-picker-content {
        height: auto;
    }

    .ant-picker-cell-inner {
        height: 2.625rem;
        line-height: 2.625rem;
        width: 100%;
        font-size: 0.8125rem;
    }

    .ant-picker-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        .ant-picker-cell-inner {
            min-width: 1.875rem;
            height: 1.875rem;
            line-height: 2rem;
            margin-bottom: 0;
        }
    }

    row-gap: 2px;
}

.datePicker tbody tr {
    display: grid;
    grid-auto-flow: column;
}

.datePicker thead tr {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 2px;

    th {
        width: 30px !important;
        font-size: 0.75rem;
    }
}