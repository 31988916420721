.error-page {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - var(--container-vertical-padding) * 2 - var(--header-height));
    z-index: 1;
    position: relative;
    background: white;
}

.error-page.home_403 {
    height: calc(100vh  - var(--header-height));
}

.error-page.full_height {
    height: calc(100vh);
}