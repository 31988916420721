.errorPage {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - var(--container-vertical-padding) * 2 - var(--header-height));
}

.imageWrapper {
    width: 180px;
    height: 107px;

    img {
        width: 100%;
        height: 100%;
    }
}

.textBlock {
    text-align: center;
    max-width: 27.1875rem;
    padding: 0 0.625rem;
}

.homeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 7.5rem;
    min-height: 2.625rem;
    background: #eef9ff;
    border-radius: 10px;
    color: #0076b3;
    padding: 0 1.375rem;
    transition: .3s background;

    &:hover {
        background: #e0f3ff;
        color: #0076b3 !important;
    }
}

.copiedHint {
    cursor: pointer;
    color: #999999;
    transition: 0.3s color;

    &:hover {
        color: #444444;
    }
}
