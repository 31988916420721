.truncatedWrapper {
    display: inline-flex;
    min-width: 0;

    &.align {
        align-items: center;
    }

    &.fullWidth {
        max-width: 100%;
    }

    .wrappedInner {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
}

.fullWrapper {
    padding: 1rem 2rem;

    .wrappedInner {
        text-align: left !important;
        overflow-wrap: break-word;
        white-space: pre-wrap !important;
        line-height: 1.1875rem;
        font-size: 0.875rem;
        color: #444444;
    }
}

.truncatedWrapper, .fullWrapper {
    .wrappedInner {
        display: block;
        color: inherit;
    }
}