.dropdown {
    position: relative;
    padding: 1.25rem 0 1.875rem 0;
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(1.5625rem);
}

.content {
    width: 22.75rem;
}

.location {
    padding: 1rem 1.875rem;
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.10);

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.625rem;
    padding-left: 1.625rem;
    margin-bottom: 0;
}

.item {
    line-height: 1.125rem;
    list-style-type: none;
}

.moreItems {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    color: var(--lighter-grey);
}

.thumb {
    border-radius: 6px;
    background-color: #4F5153;
}