@use "./adaptive/constants.scss";


.mainBlock {
    padding-bottom: 0.625rem;
    max-width: 44.5rem;
    margin: 0 auto;

    position: relative;
    z-index: 2;
    width: 100%;

}

@media screen and (max-width: constants.$homePageBreakPoint) {
    .mainBlock {
        padding-left: var(--mobile-container-hor-padding);
        padding-right: var(--mobile-container-hor-padding);
    }
}

.content {
    position: relative;
    z-index: 2;
    min-width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
}

.navigation {
    margin: 0 auto;
    max-width: 40rem;
}

.logout {
    color: #cccccc;
}
