.header-logo {
    display: flex;
    align-items: center;
    height: var(--header-height);

    color: var(--ocean-blue);
    text-decoration: none;
    background-color: transparent;

    justify-content: center;
    margin-right: 2.5rem;

    .logo svg {
        height: 2rem;
        width: auto;

        vertical-align: middle;
    }
}
