.dropdownList {
    list-style: none;
    padding: .625rem 0;
    margin: 0;

    &.matchHeaderColors {
        background-color: var(--header-background-color);
        border-radius: 0.375rem;
    }
}


