.ant-picker-dropdown.fardp, .ant-picker-dropdown.fardpWithResetIcon {
    .ant-picker-time-panel {
        display: none;
    }

    .ant-picker-range-arrow {
        top: 0.313rem !important;
    }
}

.ant-picker.ant-picker-range.fardp {
    & .ant-picker-input {
        width: 74px;
    }
}

.ant-picker.ant-picker-range.fardpWithResetIcon {
    & .ant-picker-input {
        width: 90px;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
}

.ant-picker-separator {
    color: #c4c4c4 !important;
}

.ant-picker-cell::before {
    content: none;
}

.ant-picker-cell-inner::after {
    content: none !important;
}

.ant-picker-cell:not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-start):not(td:first-child):not(td:last-child):not(.ant-picker-cell-end)
    .ant-picker-cell-inner {
    border-radius: 0;
}

td:first-child:not(.ant-picker-cell-range-start-single) .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
}

td:first-child {
    &.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
            border-radius: 2px !important;
        }
    }
}

td:last-child .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
}

.ant-picker-cell {
    &.ant-picker-cell-end .ant-picker-cell-inner {
        border-radius: 0 2px 2px 0;
    }
}

td:last-child {
    border-right-color: transparent !important;
}

td:first-child {
    border-left-color: transparent !important;
}

.ant-picker-cell-in-range {
    width: 2rem;

    .ant-picker-cell-inner {
        color: white !important;

        &:hover {
            background-color: #6792ff !important;
        }
    }
}

.ant-picker-month-panel,
.ant-picker-year-panel {
    width: 260px;

    tbody {
        row-gap: 10px !important;
    }

    .ant-picker-content {
        height: auto;
    }

    .ant-picker-cell-inner {
        line-height: 2.625rem !important;
        width: 4.375rem !important;
        height: 2.625rem !important;
    }
}

.ant-picker-decade-panel {
    tbody {
        row-gap: 10px !important;
    }

    .ant-picker-content {
        height: auto;
    }

    .ant-picker-cell-inner {
        padding: 0px 5px;
    }

    .ant-picker-cell-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1.5em !important;
        width: 4.375rem !important;
        height: 3rem !important;
    }
}

.ant-picker-body.ant-picker-body {
    padding: 0.8125rem 1.3125rem;
}

.ant-picker-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 0.5px solid transparent;
    border-left: 0.5px solid transparent;
}

.ant-picker-cell-selected,
.ant-picker-cell-range-start,
.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
        color: white !important;
        background-color: #6792ff !important;
    }
}

// needed to increase specificity of selector to rewrite styles from ant-picker component in some components
.ant-picker-cell-range-start,
.ant-picker-cell-range-end {
    .ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner:hover {
        background-color: #6792ff;
    }
}

.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner.ant-picker-cell-inner:hover {
    background-color: var(--silky);
}

.ant-picker-footer {
    .ant-picker-footer-extra {
        border-bottom: none;
        padding: 0 !important;
    }

    .ant-picker-ranges {
        display: none;
    }
}

.calendarIcon {
    margin-right: 0.4375rem;
    width: 1.25rem;
    fill: #999 !important;
}

.ant-picker-cell-disabled {
    .ant-picker-cell-inner {
        opacity: 0.5;
    }
}

.range {
    color: white !important;
    background-color: #6792ff !important;
}

.resetButton {
    z-index: 2;
    cursor: pointer;
    margin-right: 0.5rem;

    svg {
        fill: #666666;
    }
}

.ant-picker.fardp, .ant-picker.fardpWithResetIcon {
    background-color: inherit !important;
    width: auto !important;

    &::after {
        content: none;
    }

    input {
        &::placeholder {
            color: #999999;
        }

        color: #444444 !important;
    }

    .ant-picker-active-bar {
        background-color: #ffc719 !important;
        margin-left: 0 !important;
        bottom: -0.375rem !important;
    }
}

.fardp tbody, .fardpWithResetIcon tbody {
    min-height: 100%;
    display: grid;
    row-gap: 2px;
}

.fardp tbody tr, .fardpWithResetIcon tbody tr {
    display: grid;
    grid-auto-flow: column;

    td {
        padding: 0 !important;
    }

    .ant-picker-cell-inner {
        min-width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

.fardp thead tr, .fardpWithResetIcon thead tr {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;

    th {
        width: 32px !important;
    }
}

