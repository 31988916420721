body .buorg {
    display: block;
    background-color: #4C545B;
    font-family: var(--font-stack);
    font-size: var(--font-size);
    line-height: 1.25rem;
    animation: 1s ease-out 0s buorgfly;
    width: 21.25rem;
    text-align: left;
    padding: 1.37500rem 1.87500rem 1.5rem;
    position: absolute;
    right: 1.25rem;
    bottom: 1.25rem;
    top: unset;
    left: unset;
    border-radius: 0.375rem;
    &-mainmsg {
        font-family: var(--font-stack);
        font-style: normal;
        color: #C0C0C0;
        text-transform: uppercase;
        font-size: 0.8125rem;
        line-height: 1.12500rem;
        font-weight: 600;
    }
    &-moremsg {
        color: #ffffff;
        display: block;
        margin-top: 0.625rem;
        margin-bottom: 0.875rem;
        line-height: 1.25rem;
    }
    &-buttons a {
        box-shadow: none !important;

        &:first-child {
            background-color: var(--yellow) !important;
            color: var(--font-color) !important;
        }

        &:last-child {
            background-color: #57616A !important;
            color: #C0C0C0 !important;
        }
    }

}

body .buorg-pad {
    padding: unset;
    line-height: unset;
}

body .buorg-buttons {
    margin-left: -0.5rem;
    display: flex;
    margin-top: 1rem;
}

body #buorgig,
body #buorgul,
body #buorgpermanent {
    text-decoration: none;
    cursor: pointer;
    border-radius: 0.25rem;
    font-weight: normal;
    white-space: nowrap;
    margin: 0 0.5rem;
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    vertical-align: center;
    padding: 0 1.5rem;
    box-shadow: inset 0 0 0 0.125rem var(--yellow);
}

@media only screen and (max-width: 43.75rem) {
    body .buorg div {
        padding: 0.3125rem 0.75rem 0.3125rem 0.5625rem;
        line-height: 1.3em;
    }
}

@keyframes buorgfly {
    from {
        opacity: 0;
        transform: translate(-100%, -100%) !important;
    }
    to {
        opacity: 1;
        transform: translate(-100%, -100%) !important;
    }
}

body .buorg-fadeout {
    transition: visibility 0s 8.5s, opacity 8s ease-out 0.5s;
}

body .buorg-icon {
    width: 1.375rem;
    height: 1rem;
    background-size: cover;
}
