.dateAndTime {
    display: flex;
    flex-direction: column;
    row-gap: 0.1875rem;
}

.flexContainer {
    display: flex;
    align-items: center;
}

.label {
    width: 11.375rem;
    display: flex;
    align-items: center;
    color: #444444;
}

.select {
    padding: 0 !important;
}
